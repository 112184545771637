import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/checkbox/Checkbox.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const InternalContent = makeShortcode("InternalContent");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/checkbox" storybook="https://workday.github.io/canvas-kit/?path=/story/components-inputs-checkbox--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=28441-6260&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/8635feb5ca1a154d0bd622baa93a35e3/536c7/component-anatomy-checkbox-group.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "27.027027027027025%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Checkbox Group in its default state.",
              "title": "Image of a Checkbox Group in its default state.",
              "src": "/static/8635feb5ca1a154d0bd622baa93a35e3/50383/component-anatomy-checkbox-group.png",
              "srcSet": ["/static/8635feb5ca1a154d0bd622baa93a35e3/1efb2/component-anatomy-checkbox-group.png 370w", "/static/8635feb5ca1a154d0bd622baa93a35e3/50383/component-anatomy-checkbox-group.png 740w", "/static/8635feb5ca1a154d0bd622baa93a35e3/536c7/component-anatomy-checkbox-group.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Form Field Label:`}</strong>{` The Form Field Label describes all of the checkboxes in the checkbox group
and functions as a header.`}</li>
        <li parentName="ol"><strong parentName="li">{`Checkbox:`}</strong>{` Checkboxes are aligned close to its label or by itself in some cases.`}</li>
        <li parentName="ol"><strong parentName="li">{`Checkbox Label:`}</strong>{` Checkbox Labels give information about what to select or unselect.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`The Form Field Label can be positioned in two places; above or left of the checkbox group for LTR
languages. Form Field Labels are aligned to the right of the checkbox group for RTL languages.`}</li>
        <li parentName="ul">{`Checkbox Labels are positioned to the right of Checkboxes for LTR languages or to the left of
Checkboxes for RTL languages.`}</li>
        <li parentName="ul">{`Checkboxes allow users to select one or many options. Selected options are shown as a white check
with blue fill. Clicking it again will deselect the choice.`}</li>
        <li parentName="ul">{`Each Checkbox is tied to a distinct value. Label for each selection should describe the choice and
be kept as concise as possible. See these `}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/checkboxes"
          }}>{`guidelines`}</a>{` for more
information on writing Checkbox labels.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`Use Checkboxes when the user is allowed to select 0, 1, or multiple values from a predefined list
of 7 or less options.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`Consider using a `}<a parentName="li" {...{
            "href": "/components/inputs/switch"
          }}>{`Switch`}</a>{` if the only options are yes or no.`}</li>
        <li parentName="ul">{`For a list between 2 to 7 predefined options, consider using
`}<a parentName="li" {...{
            "href": "/components/inputs/radio"
          }}>{`Radio Buttons`}</a>{` or a `}<a parentName="li" {...{
            "href": "/components/inputs/select"
          }}>{`Select`}</a>{` to select one
option.`}</li>
        <li parentName="ul">{`Use a Prompt when the number of list items is large or unknown. Prompts have search capabilities
and folders which provide users with the means to browse options. Prompts can be configured to
support single or multi-select.`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "how-checkboxes-impact-the-accessible-experience"
      }}>{`How Checkboxes Impact the Accessible Experience`}</h3>
      <p>{`Checkboxes used to present a group of choices require context, such as, selecting your pizza
toppings. Designing a clear legend and building the context of the group is a key foundation for
accessible checkboxes when users cannot visually perceive the layout.`}</p>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Each Checkbox must have a focus indicator that is highly visible against the background and against
the non-focused state. Refer to `}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for more
information.`}</p>
      <p>{`Checkboxes must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: focus a checkbox`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Space`}</inlineCode>{`: select/unselect a focused checkbox`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Checkboxes must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`The name (Form Field Label) of the checkbox grouping, if applicable`}</li>
        <li parentName="ul">{`The accessible name (label) of the focused checkbox`}</li>
        <li parentName="ul">{`Whether the checkbox is selected or unselected`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <ul>
        <li parentName="ul">{`Write the context (legend) of the checkbox grouping, if applicable.`}</li>
      </ul>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`Checkbox must have a `}<inlineCode parentName="li">{`required`}</inlineCode>{` attribute when the Checkbox is required for submission.`}</li>
        <li parentName="ul">{`Checkbox must have an `}<inlineCode parentName="li">{`aria-invalid=”true”`}</inlineCode>{` attribute when it has an error condition.`}</li>
        <li parentName="ul">{`[Included in component]`}{` A `}<inlineCode parentName="li">{`<label>`}</inlineCode>{` element is required with a `}<inlineCode parentName="li">{`for`}</inlineCode>{` attribute referencing the
unique `}<inlineCode parentName="li">{`id`}</inlineCode>{` value of the associated Checkbox.`}</li>
        <li parentName="ul">{`[Included in component]`}{` A `}<inlineCode parentName="li">{`<fieldset>`}</inlineCode>{` element establishes the group of related Checkboxes. The
child `}<inlineCode parentName="li">{`<legend>`}</inlineCode>{` element describes the context of the grouping.`}</li>
        <li parentName="ul">{`[Included in component]`}{` A `}<inlineCode parentName="li">{`disabled`}</inlineCode>{` attribute is set when the Checkbox is disabled.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`Form Field Labels are written in title case.`}</li>
        <li parentName="ul">{`The Checkbox Label for each individual selection are kept as concise as possible and written in
sentence case.`}</li>
        <li parentName="ul">{`When writing Checkbox Labels, refer to the `}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/checkboxes"
          }}>{`Checkboxes`}</a>{` section of
the Content Style Guide.`}</li>
      </ul>
    </TabPanel>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="iOS" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?node-id=20%3A4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-1"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/d944e0ff4770bef64349e4e0489cf12c/536c7/component-anatomy-checkbox-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Checkbox Group in its default state.",
                "title": "Image of a Checkbox Group in its default state.",
                "src": "/static/d944e0ff4770bef64349e4e0489cf12c/50383/component-anatomy-checkbox-mobile.png",
                "srcSet": ["/static/d944e0ff4770bef64349e4e0489cf12c/1efb2/component-anatomy-checkbox-mobile.png 370w", "/static/d944e0ff4770bef64349e4e0489cf12c/50383/component-anatomy-checkbox-mobile.png 740w", "/static/d944e0ff4770bef64349e4e0489cf12c/536c7/component-anatomy-checkbox-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Container`}</strong>{`: Main container of the Checkbox`}</li>
          <li parentName="ol"><strong parentName="li">{`Checkmark`}</strong>{`: System icon used to indicate if the item is selected or unselected.`}</li>
          <li parentName="ol"><strong parentName="li">{`Checkbox Label`}</strong>{`: Checkbox Labels give information about what to select or unselect.`}</li>
        </ol>
        <h2 {...{
          "id": "interaction-states"
        }}>{`Interaction States`}</h2>
        <p>{`Mobile checkboxes support the Inactive, Pressed, Selected, and Disabled states.
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/99ac0850c56c3655e70c5f08ad023ec5/536c7/component-states-checkbox-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Checkbox Interaction states.",
                "title": "Image of Checkbox Interaction states.",
                "src": "/static/99ac0850c56c3655e70c5f08ad023ec5/50383/component-states-checkbox-mobile.png",
                "srcSet": ["/static/99ac0850c56c3655e70c5f08ad023ec5/1efb2/component-states-checkbox-mobile.png 370w", "/static/99ac0850c56c3655e70c5f08ad023ec5/50383/component-states-checkbox-mobile.png 740w", "/static/99ac0850c56c3655e70c5f08ad023ec5/536c7/component-states-checkbox-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "usage-guidance-1"
        }}>{`Usage Guidance`}</h2>
        <ul>
          <li parentName="ul">{`Checkbox Labels are positioned to the right of the Checkbox for LTR languages or to the left of
Checkbox for RTL languages.`}</li>
          <li parentName="ul">{`Checkboxes allow users to select one or many options. Selected options are shown as a white check
with blue fill. Tapping it again will deselect the choice.`}</li>
          <li parentName="ul">{`Each Checkbox is tied to a distinct value. Labels for each selection should describe the choice
and be kept as concise as possible. Please reference the
`}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/checkboxes"
            }}>{`Checkbox section`}</a>{` of the Content style guide for more information on
writing Checkbox labels.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-1"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`Use checkboxes when the user is allowed to select 0, 1, or multiple values from a predefined list
of 7 or less options.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-1"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`Consider using a Switch for binary options such as yes/no or active/disabled.`}</li>
          <li parentName="ul">{`Use a `}<a parentName="li" {...{
              "href": "/components/inputs/radio#tab=ios"
            }}>{`Radio Button`}</a>{` to select a single option from a list of 2
to 7 options.`}</li>
          <li parentName="ul">{`Use a Prompt when the number of list items is large or unknown. Prompts have search capabilities
and folders which provide users with the means to browse options. Prompts can be configured to
support single or multi-select.`}</li>
        </ul>
        <h3 {...{
          "id": "mobile-guidance"
        }}>{`Mobile Guidance`}</h3>
        <ul>
          <li parentName="ul">{`Ensure a minimum tap target of 48dp to ensure that Checkboxes are easy to interact with.`}</li>
          <li parentName="ul">{`For Checkboxes, an additional 12dp of tappable horizontal and vertical padding are added to meet
the minimum touch target.`}</li>
        </ul>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Use a minimum tap target of 48dp to ensure the Checkbox is easy to interact with." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/c8b2253537af393e54e981dfa6c6842a/3cb0f/component-do-taptarget-checkbox-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Checkbox with a minimum tap target of 48dp.",
                    "title": "Image demonstrating a Checkbox with a minimum tap target of 48dp.",
                    "src": "/static/c8b2253537af393e54e981dfa6c6842a/3cb0f/component-do-taptarget-checkbox-mobile.png",
                    "srcSet": ["/static/c8b2253537af393e54e981dfa6c6842a/1efb2/component-do-taptarget-checkbox-mobile.png 370w", "/static/c8b2253537af393e54e981dfa6c6842a/3cb0f/component-do-taptarget-checkbox-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Use a tap target height smaller than 48dp, even if the Checkbox might appear smaller visually." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/cbdf7be3e4fbd236f5f8d2a43aa80e23/3cb0f/component-dont-taptarget-checkbox-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Checkbox without a minimum tap target of 48dp.",
                    "title": "Image demonstrating a Checkbox without a minimum tap target of 48dp.",
                    "src": "/static/cbdf7be3e4fbd236f5f8d2a43aa80e23/3cb0f/component-dont-taptarget-checkbox-mobile.png",
                    "srcSet": ["/static/cbdf7be3e4fbd236f5f8d2a43aa80e23/1efb2/component-dont-taptarget-checkbox-mobile.png 370w", "/static/cbdf7be3e4fbd236f5f8d2a43aa80e23/3cb0f/component-dont-taptarget-checkbox-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h2 {...{
          "id": "api-guidelines"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "component-definition"
        }}>{`Component Definition`}</h3>
        <p><inlineCode parentName="p">{`Checkbox`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public struct Checkbox: View
`}</code></pre>
        <p>{`WD-Standard Checkbox component, a custom SwiftUI view. This can be either a compound checkbox (re:
CheckboxInput) or a simple checkbox with associated text, depending on the params sent. Checkbox is
a custom view to meet accessibility needs for this component.`}</p>
        <h3 {...{
          "id": "properties"
        }}>{`Properties`}</h3>
        <p><inlineCode parentName="p">{`body`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public var body: some View
`}</code></pre>
        <h3 {...{
          "id": "methods"
        }}>{`Methods`}</h3>
        <p><inlineCode parentName="p">{`init(_:label:helperText:isSelected:context:accessibilityLabel:)`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public init(
    _ text: String = "",
    label: String = "",
    helperText: String? = nil,
    isSelected: Binding<Bool>,
    context: SemanticContext = .default,
    accessibilityLabel: String = ""
)
`}</code></pre>
        <p>{`Creates an instance of Checkbox, applying a pre-computed accessibilityLabel.`}</p>
        <h4 {...{
          "id": "parameters"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`text`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Text to the right of the checkbox, already localized.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`label`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Label to be positioned above the checkbox, already localized.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`helperText`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Helper text below checkbox, already localized.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`isSelected`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Whether or not the checkbox is selected by default.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`context`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`SemanticContext`}</inlineCode>{` of the field.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`acccessibilityLabel`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`accessibilityLabel used for this component, already localized.`}</td>
            </tr>
          </tbody>
        </table>
        <p><inlineCode parentName="p">{`init(_:label:helperText:isSelected:context:localizer:)`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public init(
    _ text: String = "",
    label: String = "",
    helperText: String? = nil,
    isSelected: Binding<Bool>,
    context: SemanticContext = .default,
    localizer: LocalizationAdapting
)
`}</code></pre>
        <p>{`Creates an instance of Checkbox, with default accessibility behavior when provided a localizer.`}</p>
        <h4 {...{
          "id": "parameters-1"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`text`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Text to the right of the checkbox, already localized.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`label`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Label to be positioned above the checkbox, already localized.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`helperText`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Helper text below checkbox. Helper text will be prefixed with “Error:”/“Warning:” as part of this init.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`isSelected`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Whether or not the checkbox is selected by default.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`context`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`SemanticContext`}</inlineCode>{` of the field.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`localizer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`localizer used for localizing the helper text (and accessibility text) of this component.`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-1"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Refreshed mobile guidelines will be coming soon!`}</p>
        <h2 {...{
          "id": "content-guidelines-1"
        }}>{`Content Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`The Checkbox Label for each individual selection is kept as concise as possible, and written in
sentence case.`}</li>
          <li parentName="ul">{`When writing Checkbox labels, refer to the `}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/checkboxes"
            }}>{`Checkbox section`}</a>{` of the
Content Style Guide.`}</li>
        </ul>
      </TabPanel>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="Android" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?node-id=20%3A4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-2"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/d944e0ff4770bef64349e4e0489cf12c/536c7/component-anatomy-checkbox-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Checkbox Group in its default state.",
                "title": "Image of a Checkbox Group in its default state.",
                "src": "/static/d944e0ff4770bef64349e4e0489cf12c/50383/component-anatomy-checkbox-mobile.png",
                "srcSet": ["/static/d944e0ff4770bef64349e4e0489cf12c/1efb2/component-anatomy-checkbox-mobile.png 370w", "/static/d944e0ff4770bef64349e4e0489cf12c/50383/component-anatomy-checkbox-mobile.png 740w", "/static/d944e0ff4770bef64349e4e0489cf12c/536c7/component-anatomy-checkbox-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Container`}</strong>{`: Main container of the Checkbox`}</li>
          <li parentName="ol"><strong parentName="li">{`Checkmark`}</strong>{`: System icon used to indicate if the item is selected or unselected.`}</li>
          <li parentName="ol"><strong parentName="li">{`Checkbox Label`}</strong>{`: Checkbox Labels give information about what to select or unselect.`}</li>
        </ol>
        <h2 {...{
          "id": "interaction-states-1"
        }}>{`Interaction States`}</h2>
        <p>{`Mobile checkboxes support the Inactive, Pressed, Selected, and Disabled states.
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/99ac0850c56c3655e70c5f08ad023ec5/536c7/component-states-checkbox-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Checkbox Interaction states.",
                "title": "Image of Checkbox Interaction states.",
                "src": "/static/99ac0850c56c3655e70c5f08ad023ec5/50383/component-states-checkbox-mobile.png",
                "srcSet": ["/static/99ac0850c56c3655e70c5f08ad023ec5/1efb2/component-states-checkbox-mobile.png 370w", "/static/99ac0850c56c3655e70c5f08ad023ec5/50383/component-states-checkbox-mobile.png 740w", "/static/99ac0850c56c3655e70c5f08ad023ec5/536c7/component-states-checkbox-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "usage-guidance-2"
        }}>{`Usage Guidance`}</h2>
        <ul>
          <li parentName="ul">{`Checkbox Labels are positioned to the right of the Checkbox for LTR languages or to the left of
Checkbox for RTL languages.`}</li>
          <li parentName="ul">{`Checkboxes allow users to select one or many options. Selected options are shown as a white check
with blue fill. Tapping it again will deselect the choice.`}</li>
          <li parentName="ul">{`Each Checkbox is tied to a distinct value. Labels for each selection should describe the choice
and be kept as concise as possible. Please reference the
`}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/checkboxes"
            }}>{`Checkbox section`}</a>{` of the Content style guide for more information on
writing Checkbox labels.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-2"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`Use checkboxes when the user is allowed to select 0, 1, or multiple values from a predefined list
of 7 or less options.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-2"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`Consider using a Switch for binary options such as yes/no or active/disabled.`}</li>
          <li parentName="ul">{`Use a `}<a parentName="li" {...{
              "href": "/components/inputs/radio#tab=android"
            }}>{`Radio Button`}</a>{` to select a single option from a list
of 2 to 7 options.`}</li>
          <li parentName="ul">{`Use a Prompt when the number of list items is large or unknown. Prompts have search capabilities
and folders which provide users with the means to browse options. Prompts can be configured to
support single or multi-select.`}</li>
        </ul>
        <h3 {...{
          "id": "mobile-guidance-1"
        }}>{`Mobile Guidance`}</h3>
        <ul>
          <li parentName="ul">{`Ensure a minimum tap target of 48dp to ensure that Checkboxes are easy to interact with.`}</li>
          <li parentName="ul">{`For Checkboxes, an additional 12dp of tappable horizontal and vertical padding are added to meet
the minimum touch target.`}</li>
        </ul>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Use a minimum tap target of 48dp to ensure the Checkbox is easy to interact with." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/c8b2253537af393e54e981dfa6c6842a/3cb0f/component-do-taptarget-checkbox-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Checkbox with a minimum tap target of 48dp.",
                    "title": "Image demonstrating a Checkbox with a minimum tap target of 48dp.",
                    "src": "/static/c8b2253537af393e54e981dfa6c6842a/3cb0f/component-do-taptarget-checkbox-mobile.png",
                    "srcSet": ["/static/c8b2253537af393e54e981dfa6c6842a/1efb2/component-do-taptarget-checkbox-mobile.png 370w", "/static/c8b2253537af393e54e981dfa6c6842a/3cb0f/component-do-taptarget-checkbox-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Use a tap target height smaller than 48dp, even if the Checkbox might appear smaller visually." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/cbdf7be3e4fbd236f5f8d2a43aa80e23/3cb0f/component-dont-taptarget-checkbox-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Checkbox without a minimum tap target of 48dp.",
                    "title": "Image demonstrating a Checkbox without a minimum tap target of 48dp.",
                    "src": "/static/cbdf7be3e4fbd236f5f8d2a43aa80e23/3cb0f/component-dont-taptarget-checkbox-mobile.png",
                    "srcSet": ["/static/cbdf7be3e4fbd236f5f8d2a43aa80e23/1efb2/component-dont-taptarget-checkbox-mobile.png 370w", "/static/cbdf7be3e4fbd236f5f8d2a43aa80e23/3cb0f/component-dont-taptarget-checkbox-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h2 {...{
          "id": "api-guidelines-1"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "component-definition-1"
        }}>{`Component Definition`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`@Composable
fun RadioButtonUiComponent(
    modifier: Modifier = Modifier,
    checked: Boolean,
    onCheckedChange: (Boolean) -&gt; Unit?,
    enabled: Boolean = true,
    interactionSource: MutableInteractionSource = remember { MutableInteractionSource() },
    semanticState: SemanticState = SemanticState(),
    colors: CheckboxColors = CheckboxDefaults.colors())
) {
`}</code></pre>
        <h3 {...{
          "id": "parameters-2"
        }}>{`Parameters`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`modifier`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Modifier to be applied to the layout of the checkbox`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`checked`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`whether Checkbox is checked or unchecked`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onCheckedChange`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`callback to be invoked when checkbox is being clicked, therefore the change of checked state in requested. If null, then this is passive and relies entirely on a higher-level component to control the `}{`"`}{`checked`}{`"`}{` state.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`enabled`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`whether the component is enabled or grayed out.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`interactionSource`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the MutableInteractionSource representing the stream of Interactions for this Checkbox. You can create and pass in your own remembered MutableInteractionSource if you want to observe Interactions and customize the appearance / behavior of this Checkbox in different Interactions.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`semanticState`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Adjusts the state of the Component. This allows for enabling, disabling, warning, error, and required states.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`colors`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`- CheckboxColors that will be used to determine the color of the checkmark / box / border in different states.`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-2"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Refreshed mobile guidelines will be coming soon!`}</p>
        <h2 {...{
          "id": "content-guidelines-2"
        }}>{`Content Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`The Checkbox Label for each individual selection is kept as concise as possible, and written in
sentence case.`}</li>
          <li parentName="ul">{`When writing Checkbox labels, refer to the `}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/checkboxes"
            }}>{`Checkbox section`}</a>{` of the
Content Style Guide.`}</li>
        </ul>
      </TabPanel>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      